@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    :root {
        --background: 240 5% 6%;
        --foreground: 60 5% 90%;
        --primary: 240 0% 90%;
        --primary-foreground: 60 0% 0%;
        --secondary: 240 4% 15%;
        --secondary-foreground: 60 5% 85%;
        --accent: 240 0% 13%;
        --accent-foreground: 60 0% 100%;
        --destructive: 0 60% 50%;
        --destructive-foreground: 0 0% 98%;
        --muted: 240 5% 25%;
        --muted-foreground: 60 5% 85%;
        --card: 240 4% 10%;
        --card-foreground: 60 5% 90%;
        --popover: 240 5% 15%;
        --popover-foreground: 60 5% 85%;
        --border: 240 6% 20%;
        --input: 240 6% 20%;
        --ring: 240 5% 90%;
        --tag: 240 5% 20%;
        --tag-foreground: 60 5% 90%;
        --timeframe: 280 70% 70%;
        --timeframe-foreground: 280 70% 100%;
        --radius: 0.5rem;
        --custom-green: 152 47% 31%; /* Verde escuro */
        --custom-green-hover: 152 47% 25%; /* Verde ainda mais escuro para hover */
        --custom-green-foreground: 0 0% 100%; /* Branco */
    }

    @media (max-width: 640px) {
        :root {
            --radius: 0.3rem;
        }
    }
}

@layer base {
    * {
        @apply border-border;
    }
    body {
        @apply bg-background text-foreground;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @apply font-bold;
    }
}

@layer components {
    .task-dropdown {
        @apply bg-secondary text-secondary-foreground rounded-md shadow-lg p-2 mt-2;
    }
    .task-dropdown-item {
        @apply flex items-center space-x-2 py-1 px-2 hover:bg-accent hover:text-accent-foreground rounded transition-colors;
    }
    .tag {
        @apply inline-flex items-center rounded-md text-xs font-medium bg-secondary text-secondary-foreground;
    }
    .timeframe {
        @apply inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-timeframe text-timeframe-foreground;
    }
}

/* Adicionando classes utilitárias para melhorar a responsividade */
@layer utilities {
    .responsive-padding {
        @apply p-2 sm:p-4 md:p-6;
    }
    .responsive-margin {
        @apply m-2 sm:m-4 md:m-6;
    }
    .responsive-text {
        @apply text-sm sm:text-base md:text-lg;
    }
    .responsive-grid {
        @apply grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5;
    }
}
